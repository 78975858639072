<template>
  <el-dialog class="js-dialog-form" title="新增标准作业" :visible.sync="dialogVisible" :before-close="handleCancel" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label common-tip"><i>*</i>名称</li> -->
      <li>
        <div class="label common-tip"><i>*</i>名称</div>
        <el-input v-model="name" placeholder="请输入名称" size="small"></el-input>
      </li>
      <!-- <li class="label">作业码</li> -->
      <li>
        <div class="label common-tip"><i>*</i>作业码</div>
        <el-input v-model="code" placeholder="请输入创建人" size="small"></el-input>
      </li>
      <!-- <li class="label">备注</li> -->
      <li>
        <div class="label common-tip"><i>*</i>备注</div>
        <el-input v-model="remark" type="textarea" :rows="4" placeholder="请输入备注关键字" size="small"></el-input>
      </li>
      <!-- <li class="label common-tip"><i>*</i>全程录像</li> -->
      <li>
        <div class="label common-tip"><i>*</i>全程录像</div>
        <el-radio-group v-model="wholeVideo" size="small">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data() {
    return {
      name: '',
      code: '',
      remark: '',
      wholeVideo: 1
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {},
  methods: {
    handleCancel(){
      this.name = ''
      this.code = ''
      this.remark = ''
      this.wholeVideo = 1
      this.dialogVisible = false
    },
    handleConfirm(){
      let params = {
        name: this.name,
        code: this.code,
        remark: this.remark,
        wholeVideo: this.wholeVideo
      }
      this.$emit('handleAdd',params)
      this.handleCancel()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>