<template>
  <el-dialog class="js-dialog-form" title="修改标准作业" :visible.sync="dialogVisible" :before-close="handleCancel" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label common-tip"><i>*</i>名称</li> -->
      <li>
        <div class="label common-tip"><i>*</i>名称</div>
        <el-input v-model="params.name" placeholder="请输入名称" size="small"></el-input>
      </li>
      <!-- <li class="label">作业码</li> -->
      <li>
        <div class="label common-tip"><i>*</i>作业码</div>
        <el-input v-model="params.code" placeholder="请输入作业码" size="small"></el-input>
      </li>
      <!-- <li class="label">备注</li> -->
      <li>
        <div class="label common-tip"><i>*</i>备注</div>
        <el-input v-model="params.remark" type="textarea" :rows="4" placeholder="请输入备注关键字" size="small"></el-input>
      </li>
      <!-- <li class="label common-tip"><i>*</i>全程录像</li> -->
      <li>
        <div class="label common-tip"><i>*</i>全程录像</div>
        <el-radio-group v-model="params.wholeVideo" size="small">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </li>
      <!-- <li class="label common-tip"><i>*</i>状态</li> -->
      <li>
        <div class="label common-tip"><i>*</i>状态</div>
        <el-radio-group v-model="params.status" size="small">
          <el-radio :label="0">停用</el-radio>
          <el-radio :label="1">启用</el-radio>
        </el-radio-group>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value','currentItem'],
  data() {
    return {
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    },
    params(){
      return this.currentItem
    }
  },
  mounted() {},
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      this.$emit('handleItem',this.params)
    }
  },
};
</script>

<style lang="scss" scoped>

</style>